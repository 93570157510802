import React, { Suspense, lazy } from 'react';
import { Spinner } from 'react-bootstrap';

// Import Banner directly
import Banner from '../../components/Home/Banner';

// Lazy load other components
const About = lazy(() => import('../../components/Home/About'));
const Features = lazy(() => import('../../components/Home/Features'));
const Services = lazy(() => import('../../components/Home/Services'));
const Testimonials = lazy(() => import('../../components/Home/Testimonials'));
const Faqs = lazy(() => import('../../components/Home/Faqs'));
const CTA = lazy(() => import('../../components/Home/CTA'));
const HowTo = lazy(() => import('../../components/Home/HowTo'));

export default function Home() {
    return (
        <div id='home'>
            <Banner />
            <Suspense fallback={<Spinner animation="border" variant="dark" />}>
                {/* <Partners /> */}
                <HowTo />
                <About />
                <Features />
                <Services />
                {/* <Blogs /> */}
                <Testimonials />
                <Faqs />
                <CTA />
            </Suspense>
        </div>
    );
}
