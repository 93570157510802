import React, { useState, useEffect } from 'react';
import { FaUsers, FaCoins } from "react-icons/fa";
import CoinTable from '../../components/Tables/CoinTable';
import axios from 'axios';
import LogTable from '../../components/Tables/LogTable';
import UsersTable from '../User/UsersTable';
import { Spinner } from 'react-bootstrap';

const StatCard = ({ title, value, Icon, color }) => (
    <div className="col-6 col-md-2">
        <div className="card">
            <div className="card-body text-center">
                <h5 className="card-title mb-0">{title}</h5>
                <div className="d-flex justify-content-between align-items-center">
                    <Icon size={50} className={color} />
                    <h2 className='mb-0 mt-1'>{value}</h2>
                </div>
            </div>
        </div>
    </div>
);

const LoadingPlaceholder = () => (
    <div className="col-6 col-md-2">
        <div className="card">
            <div className="card-body text-center">
                <h5 className="card-title mb-0 skeleton">Loading...</h5>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="skeleton" style={{ width: '50px', height: '50px', borderRadius: '50%', backgroundColor: '#e0e0e0' }}></div>
                    <div className="skeleton" style={{ width: '60px', height: '24px', backgroundColor: '#e0e0e0' }}></div>
                </div>
            </div>
        </div>
    </div>
);

export default function AdminDashboard() {
    const [stats, setStats] = useState({
        totalUsers: 0,
        unverifiedUsers: 0,
        kycCompleted: 0,
        inactiveUsers: 0,
        totalCoins: 0,
        totalTradePairs: 0
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/statistics`, { withCredentials: true });
                setStats(response.data);
            } catch (error) {
                console.error('Failed to fetch statistics:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchStats();
    }, []);

    const { totalUsers, unverifiedUsers, kycCompleted, inactiveUsers, totalCoins, totalTradePairs } = stats;

    return (
        <div className="page">
            <div className="container">
                <div className="row mt-4">
                    {loading ? (
                        <>
                            <LoadingPlaceholder />
                            <LoadingPlaceholder />
                            <LoadingPlaceholder />
                            <LoadingPlaceholder />
                            <LoadingPlaceholder />
                            <LoadingPlaceholder />
                        </>
                    ) : (
                        <>
                            <StatCard title="Users" value={totalUsers} Icon={FaUsers} color='text-info' />
                            <StatCard title="Unverified Users" value={unverifiedUsers} Icon={FaUsers} color='text-danger' />
                            <StatCard title="KYC Completed" value={kycCompleted} Icon={FaUsers} color='text-success' />
                            <StatCard title="Inactive Users" value={inactiveUsers} Icon={FaUsers} color='text-warning' />
                            <StatCard title="Coins" value={totalCoins} Icon={FaCoins} color='text-purple' />
                            <StatCard title="Trade Pairs" value={totalTradePairs} Icon={FaCoins} color='text-teal' />
                        </>
                    )}
                </div>
                <div className="row mt-3">
                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-header d-flex align-items-center">
                                <FaCoins size={30} className='me-2' /> <h3 className='mb-0'>Coins</h3>
                            </div>
                            <div className="card-body">
                                <CoinTable />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-header d-flex align-items-center">
                                <FaUsers size={30} className='me-2' /> <h3 className='mb-0'>Users</h3>
                            </div>
                            <div className="card-body">
                                <UsersTable />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="card">
                        <div className="card-body">
                            <LogTable />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
