import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function TradeQuoteModal({ show, onHide, coin1, coin2, coin1Amount, exchangeRate }) {
    const isLogged = useSelector((state) => state.user?.isLogged);
    const [walletBalance, setWalletBalance] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Calculate total cost
    const totalCost = coin1Amount * exchangeRate;

    useEffect(() => {
        if (isLogged) {
            const fetchWalletBalance = async (coinType) => {
                try {
                    const balanceResponse = await axios.get(`${process.env.REACT_APP_API_URL}/wallet/balance`, {
                        params: { coinType },
                        withCredentials: true
                    });
                    setWalletBalance(balanceResponse.data.balance);
                } catch (error) {
                    console.error('Error fetching wallet balance:', error);
                }
            };
            fetchWalletBalance(coin2?.testnetShortCode);
        }
    }, [isLogged, coin2?.testnetShortCode]);

    const handleBuyWithFiat = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/payment/generate-url`, {
                fiatAmount: 100,
                currency: 'USD',
                cryptoCurrency: coin1?.testnetShortCode,
                cryptoCurrencyShortCode: coin1?.shortCode,
            }, { withCredentials: true });

            if (response.data && response.data.moonpayUrl) {
                const width = 500;
                const height = 600;
                const left = window.screenX + (window.innerWidth - width) / 2;
                const top = window.screenY + (window.innerHeight - height) / 2;

                window.open(
                    // response.data.moonpayUrl,
                    'https://buy-sandbox.moonpay.com/?apiKey=pk_test_oxQY1qdAGKlItZrVIRQ9qpNwpfAPHjQ&theme=dark&defaultCurrencyCode=btc&baseCurrencyAmount=100&colorCode=%237d01ff',
                    '_blank',
                    `width=${width},height=${height},left=${left},top=${top},resizable,scrollbars`
                );
            }
        } catch (error) {
            console.error('Error generating payment URL:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title className='text-center'>Current Quote</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col">
                        <h3 className='text-center'>Buy {coin1Amount} {coin1?.shortCode}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <strong>Price per Coin:</strong>
                    </div>
                    <div className="col">
                        <p> {exchangeRate} {coin2?.shortCode}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <strong>Total Cost:</strong>
                    </div>
                    <div className="col">
                        <p> {totalCost.toFixed(4)} {coin2?.shortCode}</p>
                    </div>
                </div>
                {isLogged && (
                    <p>
                        <strong>Available Funds:</strong>{' '}
                        {walletBalance !== null ? (
                            walletBalance >= totalCost ? (
                                `${walletBalance} ${coin2?.shortCode}`
                            ) : (
                                <span className="text-danger">
                                    {walletBalance} {coin2?.shortCode} (Not enough funds to buy)
                                </span>
                            )
                        ) : (
                            'Loading...'
                        )}
                    </p>
                )}
                {!isLogged && (
                    <p className="text-danger">
                        This quote is indicative only. Please <Link to="/signin">sign in</Link> or <Link to="/signup">sign up</Link> to proceed with the trade.
                    </p>
                )}
            </Modal.Body>
            <Modal.Footer>
                {!isLogged ? (
                    <Button as={Link} to="/signup" variant="primary">
                        Get Started
                    </Button>
                ) : (
                    <>
                        <Button variant="secondary" onClick={onHide}>
                            Cancel
                        </Button>
                        <Button variant="primary" disabled={walletBalance < totalCost}>
                            Confirm Trade
                        </Button>
                        <Button
                            variant="warning"
                            onClick={handleBuyWithFiat}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <Spinner animation="border" size="sm" /> Loading...
                                </>
                            ) : (
                                'Buy with Fiat'
                            )}
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
}
