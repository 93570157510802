import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { LuUserCircle } from "react-icons/lu";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setIsLogged, setUserDetails } from '../../redux/userSlice';
import { setCoinData, setTradePairs } from '../../redux/coinSlice'; // Import setCoinData
import axios from 'axios';
import { Nav, Navbar } from 'react-bootstrap';

export default function Header() {
    const [isFixed, setIsFixed] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLogged = useSelector((state) => state.user?.isLogged);
    const userDetails = useSelector(state => state.user?.userDetails);

    // Fetch coins and update Redux state
    useEffect(() => {
        const fetchData = async () => {
            const coinRequest = axios.get(`${process.env.REACT_APP_API_URL}/coins`, { withCredentials: true });
            const tradePairRequest = axios.get(`${process.env.REACT_APP_API_URL}/tradePairs`, { withCredentials: true });

            try {
                const [coinsResponse, tradePairsResponse] = await Promise.all([coinRequest, tradePairRequest]);
                if (coinsResponse.data) {
                    dispatch(setCoinData(coinsResponse.data));
                }
                if (tradePairsResponse.data) {
                    dispatch(setTradePairs(tradePairsResponse.data));
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // Optionally dispatch an error action or set error state here
            }
        };

        fetchData();
    }, [dispatch]); // Empty dependency array ensures this runs once on mount

    const logOut = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/logout`, { withCredentials: true });
            if (response) {
                dispatch(setIsLogged(false));
                dispatch(setUserDetails({}));
                window.localStorage.clear();
                navigate('/');
                setExpanded(false); // Close the navbar after logging out
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleNavLinkClick = () => {
        setExpanded(false); // Close the navbar after clicking a link
    };

    return (
        <header className={`header-section header-section--style2 ${isFixed ? 'header-fixed' : ''}`}>
            <div className="header-bottom">
                <Navbar expanded={expanded} onToggle={() => setExpanded(!expanded)} collapseOnSelect expand="lg" className="bg-body-tertiary">
                    <div className="container">
                        <Navbar.Brand to="/" as={Link} onClick={handleNavLinkClick}>
                            <img className="dark logo" src="/assets/images/logo.png" alt="logo" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link to="/buy-and-sell" as={Link} onClick={handleNavLinkClick}>Buy & Sell</Nav.Link>
                                <Nav.Link to="/institutions" as={Link} onClick={handleNavLinkClick}>Institutions</Nav.Link>
                                <Nav.Link to="/otc" as={Link} onClick={handleNavLinkClick}>OTC Desk</Nav.Link>
                                <Nav.Link to="/contact-us" as={Link} onClick={handleNavLinkClick}>Contact Us</Nav.Link>
                            </Nav>
                            <Nav>
                                {isLogged ?
                                    <>
                                        {userDetails?.role === "admin" ?
                                            <>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="basic" id="dropdown-basic">
                                                        Manage Users
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item to="/admin/users" as={Link} onClick={handleNavLinkClick}>Users</Dropdown.Item>
                                                        <Dropdown.Item to="/admin/users-kyc" as={Link} onClick={handleNavLinkClick}>Users KYC</Dropdown.Item>
                                                        <Dropdown.Item to="/admins" as={Link} onClick={handleNavLinkClick}>Admins</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="basic" id="dropdown-basic">
                                                        Manage Coins
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item to="/admin/coins" as={Link} onClick={handleNavLinkClick}>Coins</Dropdown.Item>
                                                        <Dropdown.Item to="/admin/coins/trade-pairs" as={Link}>Trade Pairs</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </>
                                            :
                                            <>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="basic" id="dropdown-basic">
                                                        Wallets
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item to="/client/wallets/balances" as={Link} onClick={handleNavLinkClick}>Balances</Dropdown.Item>
                                                        <Dropdown.Item to="/client/wallets/deposits" as={Link} onClick={handleNavLinkClick}>Deposit</Dropdown.Item>
                                                        <Dropdown.Item to="/client/wallets/withdraws" as={Link} onClick={handleNavLinkClick}>Withdraw</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="basic" id="dropdown-basic">
                                                        History
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="/" onClick={handleNavLinkClick}>Trade History</Dropdown.Item>
                                                        <Dropdown.Item to="/client/history/transactions" as={Link} onClick={handleNavLinkClick}>Transactions History</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </>
                                        }
                                        <Dropdown>
                                            <Dropdown.Toggle as="div" id="dropdown-basic" className="d-flex align-items-center mt-2">
                                                {userDetails?.avatar ? <img className='img-fluid rounded-circle' style={{ height: '30px' }} src={userDetails?.avatar} alt={userDetails?.FirstName} /> : <LuUserCircle size={20} />}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {userDetails?.role === "admin" ? <Dropdown.Item as={Link} to="/admin/dashboard" onClick={handleNavLinkClick}>Dashboard</Dropdown.Item> : <Dropdown.Item as={Link} to="/client/dashboard" onClick={handleNavLinkClick}>Dashboard</Dropdown.Item>}
                                                {userDetails?.role === "admin" ? <Dropdown.Item as={Link} to="/admin/settings" onClick={handleNavLinkClick}>Settings</Dropdown.Item> : <Dropdown.Item as={Link} to="/client/settings" onClick={handleNavLinkClick}>Settings</Dropdown.Item>}
                                                <Dropdown.Item to="/client/profile-update" as={Link} onClick={handleNavLinkClick}>Profile</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { logOut(); handleNavLinkClick(); }}>Sign Out</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </>
                                    :
                                    <>
                                        <Link className="nav-link" to="/signin" onClick={handleNavLinkClick}>Sign In</Link>
                                        <Nav.Link to="/signup" className="trk-btn trk-btn--border trk-btn--primary" as={Link} onClick={handleNavLinkClick}><span>Get Started</span></Nav.Link>
                                    </>
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
            </div>
        </header>
    );
}
