import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { Button } from 'react-bootstrap'; // Import Button from react-bootstrap

export default function WalletBalances() {
    const [assets, setAssets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // Get user details from Redux
    const userDetails = useSelector(state => state.user.userDetails);
    // Get coins from Redux
    const coins = useSelector(state => state.coin.coins); // Adjust according to your coin slice path

    useEffect(() => {
        const fetchBalances = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/wallet/me`, {
                    withCredentials: true
                });
                setAssets(response.data.assets);
            } catch (error) {
                console.error('Error fetching balances:', error);
                setErrorMessage('Failed to fetch balances. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        if (userDetails?.fireblocksVaultId) {
            fetchBalances();
        }
    }, [userDetails]);

    // Function to get the coin name based on the asset ID
    const getCoinNameById = (assetId) => {
        const coin = coins.find(coin => coin.testnetShortCode === assetId);
        return coin ? coin.name : assetId; // Return coin name or assetId if not found
    };

    return (
        <div>
            {loading && <p>Loading balances...</p>}
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            {assets.length === 0 ? ( // Check if assets array is empty
                <div className="text-center">
                    <p>No assets found. Please set up your wallet.</p>
                    <Link to="/client/wallets/balances">
                        <Button variant="primary">Go to Wallet Setup</Button>
                    </Link>
                </div>
            ) : (
                <ul>
                    {assets.map((asset, index) => (
                        <li key={index}>
                            {getCoinNameById(asset.id)}: {asset.balance} ({asset.id})
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
