import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';

const LogTable = () => {
    const userDetails = useSelector(state => state.user?.userDetails);
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchLogs = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/logs`, { withCredentials: true });
            setLogs(response.data);
        } catch (err) {
            setError('Error fetching logs');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLogs();
    }, []);

    const columns = [
        {
            name: 'Action',
            selector: row => row.actionType,
            sortable: true,
        },
        {
            name: 'Details',
            selector: row => userDetails?.role === 'admin' ? row.detailsForAdmin : row.detailsForUser,
            sortable: true,
            grow: 3
        },
        {
            name: 'Timestamp',
            selector: row => moment(row.createdAt).fromNow(), // Format the timestamp
            sortable: true,
            grow: 2
        },
    ];

    // Filter logs based on user role and sort to show latest logs
    const filteredLogs = useMemo(() => {
        const logsToDisplay = userDetails?.role === 'admin'
            ? logs // Admin sees all logs
            : logs.filter(log => log.userId === userDetails?._id); // Regular user sees their logs

        // Sort logs by createdAt in descending order and limit to the latest 5 entries
        return logsToDisplay
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(0, 10); // Change this number to limit to desired entries
    }, [logs, userDetails]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" variant="dark" />
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <h3>{userDetails.role === 'admin' ? 'Admin Logs' : 'Your Logs'}</h3>
            <DataTable
                columns={columns}
                data={filteredLogs}
                pagination
                striped
                highlightOnHover
            />
        </div>
    );
};

export default LogTable;
